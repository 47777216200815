import React, { useState, createContext, useEffect, useRef } from 'react';
import { Amplify, Auth, Storage } from 'aws-amplify';
import { ApolloLink } from 'apollo-link';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from '@apollo/client/link/context';
import { navigate } from 'gatsby';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getMember } from './mutation';


const AuthContext = createContext<any>(null);
interface iProps {
  children: React.ReactNode;
}
const AuthProvider = ({ children }: iProps) => {
  const [userInfo, setUserInfo] = useState();
  const [refreshClient, setRefresh] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const [client, setClient] = useState<any>(null);

  const Init = async () => {
    const getToken = async () => {
      try {
        const jwtToken = (await Auth.currentSession())
          .getIdToken()
          .getJwtToken();
        return jwtToken;
      } catch (e) {
        return '';
      }
    };
    const token = await getToken();

    const config = {
      awsAccessKey: `${process.env.ACCESS_KEY_ID ? process.env.ACCESS_KEY_ID : process.env.GATSBY_ACCESS_KEY_ID}`,
      awsSecretKey: `${process.env.SECRET_ACCESS_KEY ? process.env.SECRET_ACCESS_KEY : process.env.GATSBY_SECRET_ACCESS_KEY}`,
      awsRegion: `${process.env.AWS_REGION ? process.env.AWS_REGION : process.env.GATSBY_AWS_REGION}`,
      identityPoolId: `${process.env.AWS_COGNITO_IDENTITY_POOL_ID ? process.env.AWS_COGNITO_IDENTITY_POOL_ID : process.env.GATSBY_AWS_COGNITO_IDENTITY_POOL_ID}`,
      awsGraphQlEndpoint: `${process.env.AWS_ENDPOINT ? process.env.AWS_ENDPOINT : process.env.GATSBY_AWS_ENDPOINT}`,
      awsSignInAuthType: `${process.env.AWS_SIGNIN_AUTH_TYPE ? process.env.AWS_SIGNIN_AUTH_TYPE : process.env.GATSBY_AWS_SIGNIN_AUTH_TYPE}`,
      awsAuthType: `${process.env.AWS_AUTH_TYPE ? process.env.AWS_AUTH_TYPE : process.env.GATSBY_AWS_AUTH_TYPE}`,
      awsUserPoolsId: `${process.env.AWS_USER_POOLS_ID ? process.env.AWS_USER_POOLS_ID : process.env.GATSBY_AWS_USER_POOLS_ID}`,
      awsCogIdentityPoolId: `${process.env.AWS_COGNITO_IDENTITY_POOL_ID ? process.env.AWS_COGNITO_IDENTITY_POOL_ID : process.env.GATSBY_AWS_COGNITO_IDENTITY_POOL_ID}`,
      awsUserPoolClientId: `${process.env.AWS_USER_POOLS_WEB_CLIENT_ID ? process.env.AWS_USER_POOLS_WEB_CLIENT_ID : process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID}`,
      awsS3Bucket: `${process.env.AWS_S3_BUCKET_NAME ? process.env.AWS_S3_BUCKET_NAME : process.env.GATSBY_AWS_S3_BUCKET_NAME}`,
      awsS3BucketLink: `${process.env.BUCKET_LINK ? process.env.BUCKET_LINK : process.env.GATSBY_BUCKET_LINK}`,
      awsS3BucketRegion: `${process.env.AWS_S3_BUCKET_REGION ? process.env.AWS_S3_BUCKET_REGION : process.env.GATSBY_AWS_S3_BUCKET_REGION}`
    }

    Amplify.configure({
      region: config.awsRegion,
      identityPoolId:config.identityPoolId,
      aws_project_region: config.awsRegion,
      aws_appsync_graphqlEndpoint: config.awsGraphQlEndpoint,
      aws_appsync_region: config.awsRegion,
      aws_appsync_authenticationType: token
        ? config.awsSignInAuthType
        : config.awsAuthType,
      aws_user_pools_id: config.awsUserPoolsId,
      aws_cognito_identity_pool_id: config.awsCogIdentityPoolId,
      aws_user_pools_web_client_id: config.awsUserPoolClientId,
      aws_user_files_s3_bucket: config.awsS3Bucket,
      aws_user_files_s3_bucket_region: config.awsS3BucketRegion,
    });

    const authLink = createAuthLink({
      url: config.awsGraphQlEndpoint,
      region: config.awsRegion,
      auth: {
        //@ts-ignore
        type: token
          ? config.awsSignInAuthType
          : config.awsAuthType,
        credentials: () => ({
          accessKeyId: config.awsAccessKey,
          secretAccessKey: config.awsSecretKey,
        }),
      },
    });

    const requestMiddleware = setContext(async (_, { headers }) => {
      const token = await getToken();
      return {
        headers: {
          ...headers,
          authorization: token ? `${token}` : '',
        },
      };
    });

    const responseMiddleWare = new ApolloLink((operation, forward) => {
      return forward(operation).map((response) => {
        return response;
      });
    });

    const client = async () => {
      const token = await getToken();
      if (token) {
        navigate('/dashboard');
        return new ApolloClient({
          //@ts-ignore
          link: ApolloLink.from([
            //@ts-ignore
            requestMiddleware,
            responseMiddleWare,
            createHttpLink({ uri: config.awsGraphQlEndpoint }),
          ]),
          cache: new InMemoryCache(),
        });
      } else {
        setUserInfo(undefined);
        setUserInfoMemberShip(undefined);
        return new ApolloClient({
          //@ts-ignore
          link: ApolloLink.from([
            authLink,
            createHttpLink({ uri: config.awsGraphQlEndpoint }),
          ]),
          cache: new InMemoryCache(),
        });
      }
    };
    const current = await client();
    setClient(current);
  };
  useEffect(() => {
    Init();
  }, []);
  const [userInfoMemberShip, setUserInfoMemberShip] = useState();
  const getUserInfo = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const res = await client.query({
        fetchPolicy: 'no-cache',
        query: getMember,
        variables: {
          memberId: user?.attributes['custom:memberId'],
        },
      });
      const data = res.data.getMember;
      const imgUrl = data?.imageUrl
        ? await Storage.get(data.imageUrl)
        : '/icons/Dashboard/profile.svg';
      setUserInfo({ ...data, imageUrl: imgUrl });
      localStorage.setItem(
        'currentUserDetail',
        JSON.stringify(res.data.getMember)
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        Init,
        client,
        refreshClient,
        setRefresh,
        refreshData,
        setRefreshData,
        userInfo,
        setUserInfo,
        userInfoMemberShip,
        setUserInfoMemberShip,
        getUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthContext, AuthProvider };
