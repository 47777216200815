import React, { useContext, useEffect, useState } from 'react';
import {
  addMemberPaymentInformation,
  chargeSinglePaymentViaVivaPay,
  getMember,
} from '@/api/mutation';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import {
  toastDismiss,
  toastError,
  toastLoading,
  toastSuccess,
} from '@/common/toasts/toast';
import { useApolloClient, useMutation } from '@apollo/client';
import { Modal } from 'react-responsive-modal';
import InputBase from '@/common/inputs/InputBase';
import axios from 'axios';
import { Auth, Storage } from 'aws-amplify';
import { navigate } from 'gatsby';
import tick from './../../assets/images/tick.svg';

interface SlideProps {
  userInfo: any;
  setModalMakePayment: (v: boolean) => void;
  amount: string;
  paymentType: string;
  changeMembershipData: {
    changeMembershipType: string;
    newMemberShipId: any;
    locationId: string;
  };
  setStep: (v: number) => void | undefined;
  openModalOutside: (v: boolean) => void;
  onCompleted: () => void;
  payloadData: any;
  isPIF: boolean;
  newLocationId;
  voucherDetail: any;
}
const ModalMakePayment: React.FC<Partial<SlideProps>> = ({
  setModalMakePayment,
  amount,
  paymentType,
  changeMembershipData,
  setStep,
  openModalOutside,
  payloadData,
  isPIF = true,
  newLocationId,
  voucherDetail,
  onCompleted,
}) => {
  const [chargeSinglePayment] = useMutation(chargeSinglePaymentViaVivaPay);
  const [addMemberPaymentInfo] = useMutation(addMemberPaymentInformation);
  const [loading, setLoading] = useState(false);
  const [confirmPay, setConfirmPay] = useState(false);
  const [cardType, setCardType] = useState('cardOnFile');
  const [modalVivaPayIsOpen, setIsOpenVivaPay] = React.useState(false);
  const [cardPrimary, setCardPrimary] = useState({});
  const [paymentInfoBank, setPaymentInfoBank] = useState({
    holderName: '',
    accountNumber: '',
    bsb: '',
  });
  const [cardRequestId, setCardRequestId] = useState('');
  const [futureOption, setFutureOption] = useState('oldCardAsDefault');
  const [paymentInformation, setPaymentInformation] = useState('');
  const [loadingMakePayment, setLoadingMakePayment] = useState(false);
  const [loadingVivaPay, setLoadingVivaPay] = useState(false);
  const [useCreditCard, setUseCreditCard] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const client = useApolloClient();
  const iframeStyle = {
    width: '100%',
    height: '98%',
    border: 'none',
    outline: 'none',
    borderRadius: '10px',
    overflow: 'hidden',
  };
  const iframeSrc = `${process.env.GATSBY_VIVAPAY_IFRAME}/${cardRequestId}`;
  const apiEndpoint = `${process.env.GATSBY_VIVAPAY_ENPOINT}/${cardRequestId}`;
  useEffect(() => {
    userInfo?.paymentDetails?.items.map((item: any) => {
      if (
        item.primary &&
        item?.vivaPaymentInformationId &&
        item?.vivaPaymentInformationId !== '' &&
        item.paymentType === 'CREDIT_CARD'
      ) {
        setCardPrimary(item);
      }
    });
  }, [userInfo]);
  const mountedFunction = () => {
    try {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          client
            .query({
              query: getMember,
              variables: {
                memberId: user.attributes['custom:memberId'],
              },
            })
            .then(async (res) => {
              const data = res.data.getMember;
              const imgUrl = data?.imageUrl
                ? await Storage.get(data.imageUrl)
                : '/icons/Dashboard/profile.svg';
              setUserInfo({ ...data, imageUrl: imgUrl });
              localStorage.setItem(
                'currentUserDetail',
                JSON.stringify(res.data.getMember)
              );
            });
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
      navigate('/');
    }
  };
  useEffect(() => {
    mountedFunction();
  }, []);
  const makeApiRequest = async () => {
    try {
      const response = await axios.get(apiEndpoint);
      return response; // Return the response when received
    } catch (error) {
      return null; // Return null on error
    }
  };
  const handlePay = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      let futurePaymentOption = '';
      let operation = '';
      if (futureOption === 'bankAccount') {
        futurePaymentOption = 'BANK_ACCOUNT_IS_DEFAULT';
      } else if (
        (cardType === 'differentCard' && futureOption === 'oldCardAsDefault') ||
        (Object.keys(cardPrimary).length && futureOption === 'oldCardAsDefault')
      ) {
        futurePaymentOption = 'CARD_ON_FILE_IS_DEFAULT';
      } else if (
        (cardType === 'differentCard' && futureOption === 'newCardAsDefault') ||
        useCreditCard
      ) {
        futurePaymentOption = 'DIFFERENT_CARD_IS_DEFAULT';
      }

      if (paymentType === 'changeMembership') {
        if (
          changeMembershipData &&
          changeMembershipData.changeMembershipType === 'upGrade'
        ) {
          operation = 'MUU';
        } else if (
          changeMembershipData &&
          changeMembershipData.changeMembershipType === 'downGrade'
        ) {
          operation = 'MDO';
        }
      } else if (paymentType === 'outstanding') {
        operation = 'OSB';
      } else if (paymentType === 'SSC') {
        operation = 'SSC';
      } else operation = paymentType;
      if (
        futureOption === 'bankAccount' &&
        (!paymentInfoBank?.accountNumber ||
          !paymentInfoBank?.bsb ||
          !paymentInfoBank?.holderName)
      ) {
        throw Error('Please fill all bank account information');
      } else if (!cardPrimary?.id && !paymentInformation.paymentInformationId) {
        throw Error('Please add card');
      } else {
        setLoadingMakePayment(true);
      }
      const payload = {
        variables: {
          amount: amount,
          memberId: user.attributes['custom:memberId'],
          brandId: userInfo?.brandId,
          operation: operation,
          futurePaymentOption: futurePaymentOption,
          ...((paymentType === 'SSC' ||
            paymentType === 'SSU' ||
            paymentType === 'AMS' ||
            paymentType === 'MCU' ||
            paymentType === 'AVM' ||
            paymentType === 'changeMembership' ||
            paymentType === 'RNM') &&
            payloadData),
          bankAccountDetails:
            futureOption === 'bankAccount' ? paymentInfoBank : undefined,
          paymentInformationId:
            Object.keys(cardPrimary).length !== 0 && cardType === 'cardOnFile'
              ? cardPrimary?.id
              : paymentInformation.paymentInformationId,
        },
      };
      const response = await chargeSinglePayment(payload);
      if (response.data.errors) {
        toastError(response.data.errors);
        typeof setModalMakePayment === 'function' &&
          !loadingMakePayment &&
          !loadingVivaPay &&
          setModalMakePayment(false);
      } else {
        typeof openModalOutside === 'function' && openModalOutside(false);
        typeof setModalMakePayment === 'function' &&
          !loadingMakePayment &&
          !loadingVivaPay &&
          setModalMakePayment(false);
        typeof setStep === 'function' && setStep(6);
        onCompleted && onCompleted();
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      !loadingMakePayment && !loadingVivaPay && setLoadingMakePayment(false);
    }
  };
  async function openModalVivaPay() {
    const user = await Auth.currentAuthenticatedUser();

    setIsOpenVivaPay(true);
    setLoading(true);
    toastLoading(
      'Please proceed by clicking "Continue button" once the payment has been successfully processed.'
    );
    try {
      const data = await addMemberPaymentInfo({
        variables: {
          input: {
            action: 'GET_SAVE_CARD_TOKEN_REQUEST',
            memberId: user.attributes['custom:memberId'],
            requestApplication: 'MEMBER_PORTAL',
            locationId: changeMembershipData.locationId
              ? changeMembershipData.locationId
              : newLocationId,
          },
        },
      });
      if (data?.data.addMemberPaymentInformation.cardRequestId) {
        setLoading(false);
        setCardRequestId(data.data?.addMemberPaymentInformation.cardRequestId);
      }
    } catch (e) {
      toastError(e.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }
  async function closeModalVivaPay() {
    toastDismiss();
    setIsOpenVivaPay(false);
    try {
      const user = await Auth.currentAuthenticatedUser();
      setLoadingVivaPay(true);
      const data = await addMemberPaymentInfo({
        variables: {
          input: {
            action: 'ADD_CREDIT_CARD_REQUEST',
            memberId: user.attributes['custom:memberId'],
            cardRequestId: cardRequestId,
            requestApplication: 'MEMBER_PORTAL',
            locationId: changeMembershipData.locationId
              ? changeMembershipData.locationId
              : newLocationId,
          },
        },
      });
      setPaymentInformation(data.data?.addMemberPaymentInformation);
      makeApiRequest().then((res) => {
        if (res?.data?.success) {
          toastSuccess('Save card successfully');
          setCardRequestId('');
        } else {
          if (!useCreditCard) {
            setCardType('cardOnFile');
          } else {
            setUseCreditCard(false);
          }
          toastError('Save card fail');
          setCardRequestId('');
        }
      });
    } catch (e) {
      toastError(e.message);
      setLoadingVivaPay(false);
    } finally {
      setLoadingVivaPay(false);
    }
  }
  const setDataBank = (e) => {
    setPaymentInfoBank({ ...paymentInfoBank, [e.target.name]: e.target.value });
  };
  function createMarkup(data) {
    return { __html: data };
  }
  function getFeeLabel(paymentType, changeMembershipData, voucherDetail) {
    if (paymentType === 'changeMembership' && changeMembershipData) {
      if (changeMembershipData.cancellationFee) {
        return 'Total';
      } else if (changeMembershipData.changeMembershipType === 'upGrade') {
        return 'Upgrade Membership Fee';
      } else if (changeMembershipData.changeMembershipType === 'downGrade') {
        return 'Downgrade Membership Fee';
      }
    }
    return '';
  }
  return (
    <div>
      <Modal
        showCloseIcon={true}
        open={true}
        onClose={() =>
          !loadingMakePayment && !loadingVivaPay && setModalMakePayment(false)
        }
        styles={{
          overlay: {
            background: 'rgba(45, 45, 35, 0.4)',
          },
          modal: {
            background: 'black',
            height: 'auto',
            width: 'auto',
            padding: 0,
          },
          modalContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <>
          {loadingMakePayment && (
            <div className="z-50 absolute h-full w-full justify-center flex bg-[#303846e6]">
              <div className="row examples m-auto ">
                <div className="  ">
                  <div className=" relative " data-title="dot-pulse">
                    <div className="stage w-[200px] m-auto !pb-5">
                      <div className="dot-pulse"></div>
                    </div>
                    <div className="mt-3 text-center text-2xl text-[#7681ff]">
                      Processing your payment
                    </div>
                    <div className="mt-5 text-center  text-white tracking-[1px]">
                      Processing time may take up to 60 seconds
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {loadingVivaPay && (
            <div className="z-50 absolute h-full w-full justify-center flex bg-[#303846e6]">
              <div className="row examples m-auto ">
                <div className="  ">
                  <div className=" relative " data-title="dot-pulse">
                    <div className="stage w-[200px]">
                      <div className="dot-pulse"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!confirmPay && paymentType !== 'changeMembership' && (
            <div className="text-white px-[33px] py-[33px]  text-center text-xl ">
              <div>
                {paymentType === 'outstanding' && 'Out Standing Balance Fee'}
                {paymentType === 'changeMembership' &&
                  changeMembershipData &&
                  changeMembershipData.changeMembershipType === 'upGrade' &&
                  'Upgrade Membership Fee'}
                {paymentType === 'changeMembership' &&
                  changeMembershipData &&
                  changeMembershipData.changeMembershipType === 'downGrade' &&
                  'Downgrade Membership Fee'}
                {paymentType === 'SSU' && 'Edit Suspension Fee'}
                {paymentType === 'MCU' && 'Edit Medical Suspension Fee'}
                {/* {paymentType === 'MCU' && 'Cancel suspension'} */}
                {paymentType === 'SSC' && 'Create Suspension Fee'}
                {paymentType === 'AMS' && 'Add Membership'}
                {paymentType === 'RMC' && 'Revoke Membership Fee'}
                {paymentType === 'AVM' &&
                  'Discounted price after applying voucher'}
                : <span className="text-primary text-2xl">${amount}</span>
              </div>
              <div className="grid grid-cols-2 mt-6 gap-6">
                <PrimaryOutlinedButton
                  onClick={() => {
                    setConfirmPay(true);
                  }}
                  className="rounded-md"
                >
                  PAY NOW
                </PrimaryOutlinedButton>
                <PrimaryOutlinedButton
                  className="hover:bg-textError hover:text-white rounded-md hover:border-none"
                  onClick={() => {
                    setModalMakePayment(false);
                  }}
                >
                  CANCEL
                </PrimaryOutlinedButton>
              </div>
            </div>
          )}
          {!confirmPay &&
            (paymentType === 'changeMembership' || paymentType === 'RNM') && (
              <Modal
                open={!confirmPay}
                onClose={() => setModalMakePayment(false)}
                styles={{
                  overlay: {
                    background: 'rgba(45, 45, 35, 0.4)',
                  },
                  modal: {
                    background: 'black',
                    height: 'auto',
                    width: 'auto',
                    padding: '0',
                  },
                  modalContainer: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                }}
              >
                <div className="flex items-center justify-between pt-[33px] md:pt-6 pr-[33px] pl-[33px] sm:px-6 ">
                  <div className="flex items-center gap-[17px] ">
                    <img
                      src={userInfo?.imageUrl}
                      className="inline-block rounded-full h-[38.7px] w-[38.7px] "
                    />
                    <span className="uppercase gotham text-[20px] text-white font-bicyclette tracking-[0.01em]">
                      hello {userInfo?.surname}
                    </span>
                  </div>
                  <div
                    onClick={() => setModalMakePayment(false)}
                    className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
                  >
                    <div className="w-4 h-4 block">
                      <img
                        src="/icons/close.png"
                        alt="close"
                        className="object-fit w-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="cancelModal w-[688px] px-[33px]  pt-8  pb-10">
                  <div className="cancelModalContent w-full h-full">
                    <div className=" flex items-center mt-7">
                      {/* Left content */}
                      <div
                        className="w-1/2 sm:hidden h-full relative opacity-80"
                        style={{
                          minWidth: '310px',
                          minHeight: '370px',
                          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)), url('/images/MyBookingsPage/cancel-booking-modal.jpg')`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                        }}
                      ></div>
                      {/* Right content */}
                      <div className="w-1/2 sm:w-full sm:pl-[33px] h-full relative sm:static left-6  sm:px-2">
                        {paymentType === 'changeMembership' ? (
                          <div>
                            <div className="text-sm text-[#7681FF] uppercase leading-4 font-medium mb-4">
                              {paymentType === 'changeMembership'
                                ? ' Are you sure you want to change current membership to this?'
                                : ''}
                            </div>
                            {Object.keys(voucherDetail || {}).length ? (
                              <div className="h-[30px] w-[250px] mb-[10px] bg-primary rounded flex justify-center items-center">
                                <p className="text-white text-sm ">
                                  PROMO CODE APPLIED
                                </p>
                              </div>
                            ) : null}
                            {changeMembershipData?.cancellationFee && (
                              <div className="text-white text-lg leading-6 mb-1">
                                <div className=" items-center mb-1">
                                  <div className="font-bold uppercase">
                                    {Object.keys(voucherDetail || {}).length
                                      ? 'Discounted price after applying voucher:'
                                      : changeMembershipData.changeMembershipType ===
                                        'upGrade'
                                      ? 'Upgrade Membership Fee:'
                                      : 'Downgrade Membership Fee:'}
                                    <span className="text-primary ml-2 text-2xl">
                                      $
                                      {Number(
                                        changeMembershipData.changeMembershipFee
                                      )?.toFixed(2)}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div className="font-bold uppercase">
                                    Cancellation Fee:
                                  </div>
                                  <div className="text-primary ml-2 text-2xl">
                                    $
                                    {Number(
                                      changeMembershipData.cancellationFee
                                    )?.toFixed(2)}
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="uppercase text-white text-xl leading-7 font-bold mb-7">
                              {getFeeLabel(
                                paymentType,
                                changeMembershipData,
                                voucherDetail
                              )}
                              :{' '}
                              <span className="text-primary text-2xl">
                                ${Number(amount)?.toFixed(2)}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <p className="uppercase  text-2xl leading-7 font-bold mb-7 text-primary ">
                            {changeMembershipData?.membershipName} <br />{' '}
                            {Object.keys(voucherDetail || {}).length ? (
                              <div className="text-white border-t my-[20px] border-t-borderLine w-fit text-center ">
                                <div className="h-[30px] w-[250px] my-[10px] bg-primary rounded flex justify-center items-center">
                                  <p className="text-white text-sm ">
                                    PROMO CODE APPLIED
                                  </p>
                                </div>
                                <ul className="mb-[34px] sm:max-w-[239px] sm:mx-auto w-full flex flex-col pt-[14px] sm:gap-[8px] gap-[10px] ">
                                  {[
                                    `$${voucherDetail?.joiningFee} Joining fee`,
                                    `$${voucherDetail?.activationFee} Activation fee`,
                                    `$${voucherDetail?.passFee} Pass fee`,
                                  ].map((i) => {
                                    if (i) {
                                      return (
                                        <li
                                          className={`flex items-center gap-[14px] sm:gap-[10px]'
                }`}
                                        >
                                          <span className="self-start w-[19px] h-[19px] flex-none rounded-[50%] flex items-center justify-center border border-white">
                                            <img
                                              className="w-auto h-auto"
                                              src={tick}
                                              alt="icon"
                                            />
                                          </span>
                                          <span
                                            className="gotham text-start font-normal sm:leading-[16px] leading-4 text-white text-sm sm:uppercase sm:text-base sm:font-bold"
                                            dangerouslySetInnerHTML={createMarkup(
                                              i?.replace('\n', '<br/>')
                                            )}
                                          ></span>
                                        </li>
                                      );
                                    }
                                  })}
                                </ul>
                              </div>
                            ) : (
                              <span>
                                <div className="border-t border-t-borderLine w-fit">
                                  <ul className="mb-[34px] sm:max-w-[239px] sm:mx-auto w-full flex flex-col pt-[14px] sm:gap-[8px] gap-[10px] ">
                                    {[
                                      ...changeMembershipData?.description.split(
                                        ','
                                      ),
                                      `Memnbership price: $${
                                        changeMembershipData?.costPrice?.toFixed(
                                          2
                                        ) ?? '0.00'
                                      }`,
                                      `Joining fee: $${
                                        changeMembershipData?.joiningFee?.toFixed(
                                          2
                                        ) ?? '0.00'
                                      }`,
                                      changeMembershipData?.joiningFee2
                                        ? `Activation fee: $${
                                            changeMembershipData?.joiningFee2?.toFixed(
                                              2
                                            ) ?? '0.00'
                                          }`
                                        : '',
                                      `Access fee: $${
                                        changeMembershipData?.joiningFee3?.toFixed(
                                          2
                                        ) ?? '0.00'
                                      }`,
                                    ].map((i) => {
                                      if (i) {
                                        return (
                                          <li
                                            className={`flex items-center gap-[14px] sm:gap-[10px]`}
                                          >
                                            <span className="self-start w-[19px] h-[19px] flex-none rounded-[50%] flex items-center justify-center border border-white">
                                              <img
                                                className="w-auto h-auto"
                                                src={tick}
                                                alt="icon"
                                              />
                                            </span>
                                            <span
                                              className="gotham text-start font-normal sm:leading-[16px] leading-4 text-white text-sm sm:uppercase sm:text-base sm:font-bold"
                                              dangerouslySetInnerHTML={createMarkup(
                                                i.replace('\n', '<br/>')
                                              )}
                                            ></span>
                                          </li>
                                        );
                                      }
                                    })}
                                  </ul>
                                </div>
                              </span>
                            )}
                            <br />
                            <div>
                              <span className="text-white text-xl">
                                Total:{' '}
                              </span>
                              <span className="text-primary text-2xl">
                                ${Number(amount).toFixed(2)}
                              </span>
                            </div>
                          </p>
                        )}

                        <div className="btnGroup flex items-center gap-x-8 sm:gap-x-4">
                          <PrimaryOutlinedButton
                            className="rounded-md w-[108px] sm:w-1/2"
                            onClick={() => setConfirmPay(true)}
                          >
                            Yes
                          </PrimaryOutlinedButton>
                          <PrimaryOutlinedButton
                            className="rounded-md w-[108px] sm:w-1/2 hover:!bg-[#ff0000] hover:!border-[#ff0000]"
                            onClick={() => setModalMakePayment(false)}
                          >
                            No
                          </PrimaryOutlinedButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
          {confirmPay && (
            <div className="text-white px-[33px] py-[33px]  ">
              <p className=" pb-6 text-[35px] mr-auto ml-auto text-center w-[482px] ">
                MAKE PAYMENT
              </p>
              <div className="grid grid-cols-3 gap-[24px]">
                <p className="col-span-3">MEMBER DETAILS</p>
                <div>
                  <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                    Member No:
                  </p>
                  <p className="text-white h-[18px] border-b border-b-white text-sm leading-[90%]">
                    {userInfo?.aliasMemberId}
                  </p>
                </div>
                <div>
                  <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                    Member Name:
                  </p>
                  <p className="text-white h-[18px] border-b border-b-white text-sm leading-[90%]">
                    {userInfo?.givenName} {userInfo?.surname}
                  </p>
                </div>
                <div>
                  <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                    Date Of Birth:
                  </p>
                  <p className="text-white border-b h-[18px] border-b-white text-sm leading-[90%]">
                    {userInfo?.dob?.split('-').reverse()?.join('/')}
                  </p>
                </div>
                <p className="col-span-3">
                  {paymentType === 'outstanding' && 'Outstanding'}
                  {paymentType === 'changeMembership' &&
                    'Change membership'}{' '}
                  Payments
                </p>

                <div className="col-span-3 grid grid-cols-2">
                  <div>
                    <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                      Amount:
                    </p>
                    <p className="text-white border-b h-[18px] border-b-white text-sm leading-[90%]">
                      {Number(amount).toFixed(2)}
                    </p>
                  </div>
                </div>
                {paymentType !== 'RNM' ? (
                  <p className="text-center text-white text-sm col-span-3">
                    Credit Card surcharge will be added to single payments only.
                    <br />
                    There is no fee for ongoing fortnightly memberships.
                    <br />
                    (VISA/MASTSERCARD: 1.5%, AMEX:3.5%)
                  </p>
                ) : null}
                {Object.keys(cardPrimary).length !== 0 &&
                cardPrimary.vivaPaymentInformationId &&
                cardPrimary.vivaPaymentInformationId !== '' ? (
                  <div className="col-span-3 text-center text-sm flex justify-evenly cursor-pointer">
                    <div
                      className="justify-evenly flex"
                      onClick={(event) => {
                        setCardType('cardOnFile');
                      }}
                    >
                      <input
                        checked={cardType === 'cardOnFile'}
                        className="mr-[10px]"
                        type="radio"
                      />
                      <p>USE CARD ON FILE</p>
                    </div>
                    <div
                      className="justify-evenly flex cursor-pointer"
                      onClick={() => {
                        setCardType('differentCard');
                        openModalVivaPay();
                      }}
                    >
                      <input
                        className="mr-[10px]"
                        type="radio"
                        checked={cardType === 'differentCard'}
                      />
                      <p>USE DIFFERENT CARD</p>
                    </div>
                  </div>
                ) : (
                  <div className="col-span-2">
                    <div
                      className=" flex cursor-pointer"
                      onClick={() => {
                        openModalVivaPay();
                        setUseCreditCard(true);
                      }}
                    >
                      <input
                        className="mr-[10px]"
                        type="radio"
                        checked={useCreditCard}
                      />
                      <p>PAY WITH CREDIT CARD</p>
                    </div>
                  </div>
                )}

                {/*Use Card On File*/}
                {(cardType === 'differentCard' || useCreditCard) &&
                  paymentInformation.cardNumber && (
                    <div className="grid grid-cols-3 col-span-3 gap-x-7">
                      <div className="col-span-1">
                        <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                          Type:
                        </p>
                        <p className="text-white border-b border-b-white text-sm h-[18px] leading-[90%]">
                          {paymentInformation?.cardType
                            ? paymentInformation?.cardType
                            : paymentInformation?.accountName}
                        </p>
                      </div>

                      <div className="col-span-1">
                        <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                          Card:
                        </p>
                        <p className="text-white border-b border-b-white  h-[18px] text-sm leading-[90%]">
                          {paymentInformation?.cardNumber}
                        </p>
                      </div>
                      <div className="col-span-1">
                        <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                          Expiry:
                        </p>
                        <p className="text-white border-b border-b-white h-[18px] text-sm leading-[90%]">
                          {paymentInformation?.cardExpiryDate
                            ? paymentInformation?.cardExpiryDate
                            : paymentInformation?.accountNumber}
                        </p>
                      </div>
                    </div>
                  )}
                {cardType === 'cardOnFile' &&
                  Object.keys(cardPrimary).length !== 0 && (
                    <div className="grid grid-cols-3 col-span-3 gap-x-7">
                      <div className="col-span-1">
                        <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                          Type:
                        </p>
                        <p className="text-white border-b border-b-white text-sm h-[18px] leading-[90%]">
                          {cardPrimary?.cardType
                            ? cardPrimary?.cardType
                            : cardPrimary?.accountName}
                        </p>
                      </div>

                      <div className="col-span-1">
                        <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                          Card:
                        </p>
                        <p className="text-white border-b border-b-white  h-[18px] text-sm leading-[90%]">
                          {cardPrimary?.cardNumber
                            ? cardPrimary?.cardNumber
                            : cardPrimary?.bsb}
                        </p>
                      </div>
                      <div className="col-span-1">
                        <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                          Expiry:
                        </p>
                        <p className="text-white border-b border-b-white h-[18px] text-sm leading-[90%]">
                          {cardPrimary?.cardExpiryDate
                            ? cardPrimary?.cardExpiryDate
                            : cardPrimary?.accountNumber}
                        </p>
                      </div>
                    </div>
                  )}
                {/*End Use Card On File*/}
                {(paymentType === 'changeMembership' ||
                  paymentType === 'AVM' ||
                  paymentType === 'RNM') &&
                  cardType !== 'cardOnFile' &&
                  !isPIF && (
                    <div className="col-span-3">
                      <p>FUTURE PAYMENT OPTION</p>
                      <div className="flex mt-[16px]">
                        <input
                          className="mr-[10px]"
                          type="radio"
                          checked={futureOption === 'oldCardAsDefault'}
                          onClick={() => setFutureOption('oldCardAsDefault')}
                        />
                        <p className="tracking-[0.5px]">
                          Future payment will be proceeded with the card above
                          as default
                        </p>
                      </div>
                      {Object.keys(cardPrimary).length !== 0 &&
                        cardType !== 'cardOnFile' && (
                          <div className="mt-[16px] flex">
                            <input
                              className="mr-[10px]"
                              type="radio"
                              checked={futureOption === 'newCardAsDefault'}
                              onClick={() =>
                                setFutureOption('newCardAsDefault')
                              }
                            />
                            <p className="tracking-[0.5px]">
                              Select this option if you would like to pay your
                              subsequent fees with the new card same as above
                              rather than the card on file
                            </p>
                          </div>
                        )}
                      {paymentType !== 'RNM' ? (
                        <div className="mt-[16px] flex">
                          <input
                            className="mr-[10px]"
                            type="radio"
                            checked={futureOption === 'bankAccount'}
                            onClick={() => setFutureOption('bankAccount')}
                          />
                          <p className="tracking-[0.5px]">
                            Select this option if you would like to enter
                            Bank/Savings Account number to pay your subsequent
                            fees rather than the above credit card
                          </p>
                        </div>
                      ) : null}
                    </div>
                  )}

                {futureOption === 'bankAccount' && (
                  <div className=" col-span-3 grid w-full md:grid-cols-1 grid-cols-2 gap-x-9 gap-y-[13px]">
                    <InputBase
                      holder="Acount name*"
                      nameInput="holderName"
                      style="lg:w-full"
                      isRequired={true}
                      onchange={(e) => setDataBank(e)}
                    />
                    <InputBase
                      holder="Account number*"
                      style="lg:w-full"
                      nameInput="accountNumber"
                      isRequired={true}
                      onchange={(e) => setDataBank(e)}
                    />
                    <InputBase
                      holder="BSB*"
                      style="lg:w-full"
                      nameInput="bsb"
                      isRequired={true}
                      onchange={(e) => setDataBank(e)}
                    />
                  </div>
                )}
                <div className="col-span-3 flex items-center justify-center gap-6">
                  <PrimaryOutlinedButton
                    onClick={() => {
                      handlePay();
                    }}
                    className="rounded-md"
                  >
                    MAKE PAYMENT
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                    className="hover:bg-textError hover:text-white rounded-md hover:border-textError"
                    onClick={() => {
                      setModalMakePayment(false);
                    }}
                  >
                    CANCEL
                  </PrimaryOutlinedButton>
                </div>
              </div>
            </div>
          )}
        </>
      </Modal>
      {modalVivaPayIsOpen && (
        <div>
          <Modal
            open={cardType === 'differentCard' || useCreditCard}
            onClose={closeModalVivaPay}
            center
            styles={{
              overlay: {
                background: 'rgba(45, 45, 35, 0.4)',
              },
              modal: {
                height: '62%',
              },
            }}
          >
            {loading ? (
              <div className="z-50 absolute top-[50%] left-[30%] ">
                <div className="row examples m-auto ">
                  <div className="  ">
                    <div className=" relative " data-title="dot-pulse">
                      <div className="stage w-[200px]">
                        <div className="dot-pulse"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <iframe
                id="pay-with-vivapay"
                title="pay with vivapay"
                src={iframeSrc}
                style={iframeStyle}
              />
            )}
            {!loading && (
              <div
                className={`h-[37px] cursor-pointer transition-all w-[130px] hover:bg-primary hover:!border-primary left-1/2 -translate-x-1/2 z-100 bg-primary absolute bottom-[2px]
              border border-white rounded-md flex gotham text-[12px] uppercase items-center justify-center text-white`}
                onClick={() => {
                  closeModalVivaPay();
                }}
              >
                Continue
              </div>
            )}
          </Modal>
        </div>
      )}
    </div>
  );
};
export default ModalMakePayment;
