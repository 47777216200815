import React, { useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import moment from 'moment/moment';
import { toastDismiss, toastError, toastLoading } from '../toasts/toast';
import PrimaryOutlinedButton from '../buttons/PrimaryOutlinedButton';
import axios from 'axios';
const PayWithVivaPay = ({
  cardRequestId,
  info,
  vivaPayApprove,
  handleNextOver18,
  handleNextUnder18,
  apiEndpoint,
  setCloseModalAfterAutoSignUp,
  setAutoSignUp,
  autoSignUp,
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [intervalId, setIntervalId] = React.useState(null);
  const [apiSuccess, setApiSuccess] = React.useState(false);

  const makeApiRequest = async () => {
    try {
      const response = await axios.get(apiEndpoint);
      return response;
    } catch (error) {
      return null;
    }
  };

  const iframeStyle = {
    width: '100%',
    height: '98%',
    border: 'none',
    outline: 'none',
    borderRadius: '10px',
    overflow: 'hidden',
  };
  const iframeSrc = `${process.env.GATSBY_VIVAPAY_IFRAME}/${cardRequestId}`;
  useEffect(() => {
    if (autoSignUp) {
      if (
        moment(new Date()).diff(moment(info?.memberDetails?.dob), 'years') < 18
      ) {
        handleNextUnder18();
      } else {
        handleNextOver18();
      }
    }
  }, [autoSignUp]);

  const openModal = () => {
    setIsOpen(true);
    toastLoading(
      'Please proceed by clicking "Continue button" once the payment has been successfully processed.'
    );

    const id = setInterval(async () => {
      const res = await makeApiRequest();
      if (res?.data?.success) {
        setAutoSignUp(true);

        setApiSuccess(true);
        clearInterval(id);
      }
    }, 1000);
    setIntervalId(id);

    // Stop the interval after 10 minutes
    setTimeout(() => clearInterval(id), 0.5 * 60 * 1000);
  };
  const handleOnClick = () => {
    if (vivaPayApprove) {
      openModal();
    } else {
      toastError('Please accept the Terms and Conditions.');
    }
  };
  const closeModal = () => {
    toastDismiss();
    setIsOpen(false);
    if (intervalId) {
      clearInterval(intervalId);
    }
    if (!apiSuccess) {
      if (
        moment(new Date()).diff(moment(info?.memberDetails?.dob), 'years') < 18
      ) {
        handleNextUnder18();
      } else {
        handleNextOver18();
      }
    } else {
      setCloseModalAfterAutoSignUp(true);
    }
  };
  return (
    <div>
      <div
        onClick={handleOnClick}
        className="h-[55px] w-[402px]  flex justify-between items-center rounded-lg cursor-pointer"
      >
        <PrimaryOutlinedButton className="rounded w-[402px]">
          {moment(new Date()).diff(moment(info?.memberDetails?.dob), 'years') <
          18
            ? 'Add payment information'
            : 'Pay now'}
        </PrimaryOutlinedButton>
      </div>
      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        center
        styles={{
          overlay: {
            background: 'rgba(45, 45, 35, 0.4)',
          },
        }}
      >
        <iframe
          id="pay-with-vivapay"
          title="pay with vivapay"
          src={iframeSrc}
          style={iframeStyle}
          className="md:!h-[93%] "
        />
        <p className="text-[#a5a5a5] text-sm mb-[100px]  flex justify-center -mt-[20px] lg:-mt-[-23px] ">
          Token ID: {cardRequestId}
        </p>
        <div
          className={`h-[37px] cursor-pointer transition-all w-[130px] hover:bg-primary hover:!border-primary left-1/2 -translate-x-1/2 z-100 bg-primary absolute bottom-[2px]
              border border-white rounded-md flex gotham text-[12px] uppercase items-center justify-center text-white`}
          onClick={() => {
            closeModal();
          }}
        >
          Continue
        </div>
      </Modal>
    </div>
  );
};
export default PayWithVivaPay;
